.HeaderBar {
  border-radius: 10px 10px 0 0;
  padding: 5px 10px;
  color: #ffffffee;
  font-weight: bold;
  text-shadow: 2px 2px 1px #ffffff77;
  box-sizing: border-box;
}

.Profile {
  width: 80%;
  margin: 0 10%;
}

@media (max-width: 1400px) {
  .Profile {
    width: 90%;
    margin: 0 5%;
  }
}

@media (max-width: 1000px) {
  .Profile {
    width: 100%;
    margin: 0;
  }
}

.FlippedAndCentered {
  border-radius: 0 0 10px 10px;
  text-align: center;
}

.MarginTop {
  margin-top: 20px;
}

.MarginBottom {
  margin-bottom: 20px;
}

.HasClicked {
  cursor: pointer;
}
