.Footer {
  width: 100%;
  margin-top: 20px;
  background-color: #ffffffaa;
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 30;
}

.Fixed{
  position: fixed;
  bottom: 0%;
}

.Footer p {
  font-size: 0.8rem;
  color: #444;
  margin: 10px 0;
  padding: 0;
  color: #444;
}

.Link {
  color: #0000ffaa;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 500px) {
  .Footer {
    margin-top: 0;
    margin-bottom: 10%;
    margin-top: 5%;
  }
}
