.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 90%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 5%;
  top: 10%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  opacity: 0;
  transform: translateY(-100vh);
}

.Modal-Visible {
  transform: translateY(0);
  opacity: 1;
}

@media (min-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Legal {
  left: 5%;
  top: 10%;
  width: 90%;
  max-height: 80%;
  overflow-y: scroll;
}

.MapModal{
  width: unset;
  left: calc(50% - 142px)
}