.Content {
  position: relative;
  display: inline-block;
  width: 70%;
  left: 15%;
  right: 15%;
  margin-top: 56px;
  box-sizing: border-box;
}

@media (max-width: 1400px){
  .Content{
    width: 80%;
    left: 10%;
    right: 10%;
  }
}

@media (max-width: 500px) {
  .Content {
    width: 100%;
    left: 0%;
    right: 0%;
    padding: 0;
  }
}
