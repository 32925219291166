.Index {
  padding: 20px 30px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffffdd;
  border: 1px solid #ccc;
  border-top: 0;
  border-radius: 0 0 20px 20px;
}

/* .LeftColumn{
  width: 70%;
  box-sizing: border-box;
  display: inline-block;
}

.RightColumn{
  width: 30%;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: auto;
  height: 100%;
} */

.Index hr {
  border-top: 1px solid #ccc;
  border-right: none;
  border-bottom: none;
  border-left: none;
}

.Index ul {
  list-style: none;
}

.Index ul li::before {
  content: "-";
  color: #2980b9;
  font-weight: 900;
  position: relative;
  left: 2px;
  margin-right: 5px;
  bottom: 1px;
}

.HeadBanner img {
  display: block;
  background-repeat: no-repeat;
  width: 80%;
  height: auto;
  margin: 0 10%;
}

.Header {
  text-align: center;
  color: #2980b9;
}

.Header span {
  font-size: 0.6em;
  margin-left: 20px;
}

.MiniHeader {
  color: #2980b9;
}

.MiniHeader::before {
  content: "";
  border-left: #2980b9 6px solid;
  position: relative;
  left: 2px;
  bottom: 1px;
  margin-right: 5px;
}

.Paragraph {
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
  align-self: center;
  box-sizing: border-box;
}

.Paragraph::before {
  content: "";
  border-left: #2980b9 6px solid;
  position: relative;
  left: 2px;
  margin-right: 5px;
  bottom: 1px;
}

.Announcement {
  font-weight: 600;
  color: #dc3545;
  font-size: 1.2em;
}

@media (max-width: 1000px) {
  .Index {
    width: 100%;
    margin: 0 auto;
  }
  .Header {
    text-align: center;
    font-size: 1.8rem;
  }
  .Paragraph {
    width: 90%;
    margin: 10px 5%;
  }
}

.bmc-button img {
  width: 27px;
  margin-bottom: 1px;
  box-shadow: none;
  border: none;
  vertical-align: middle;
}
.bmc-button {
  line-height: 36px;
  height: 37px;
  text-decoration: none;
  display: inline-block;
  color: #ffffff;
  background-color: #2980b9;
  border-radius: 3px;
  border: 1px solid transparent;
  padding: 0px 9px;
  font-size: 17px;
  letter-spacing: -0.08px;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5);
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
  margin: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -o-transition: 0.3s all linear;
  -webkit-transition: 0.3s all linear;
  -moz-transition: 0.3s all linear;
  -ms-transition: 0.3s all linear;
  transition: 0.3s all linear;
}
.bmc-button:hover,
.bmc-button:active,
.bmc-button:focus {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
  text-decoration: none;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
  opacity: 0.85;
  color: #ffffff;
}
