.Logo {
    background-color: white;
    padding: 0px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 50%;
}

.Logo img {
    height: 100%;
}