body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App__RemovedFromBody__1xWDN { /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(233, 246, 253, 1) 0%,
    rgba(211, 238, 251, 0.74) 27%,
    rgba(211, 238, 251, 0.51) 50%,
    rgba(211, 238, 251, 0.5) 51%,
    rgba(211, 238, 251, 0.68) 69%,
    rgba(233, 246, 253, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(233, 246, 253, 1) 0%,
    rgba(211, 238, 251, 0.74) 27%,
    rgba(211, 238, 251, 0.51) 50%,
    rgba(211, 238, 251, 0.5) 51%,
    rgba(211, 238, 251, 0.68) 69%,
    rgba(233, 246, 253, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9f6fd', endColorstr='#e9f6fd',GradientType=1 ); /* IE6-9 */
}
.Layout__Content__3KSp3 {
  position: relative;
  display: inline-block;
  width: 70%;
  left: 15%;
  right: 15%;
  margin-top: 56px;
  box-sizing: border-box;
}

@media (max-width: 1400px){
  .Layout__Content__3KSp3{
    width: 80%;
    left: 10%;
    right: 10%;
  }
}

@media (max-width: 500px) {
  .Layout__Content__3KSp3 {
    width: 100%;
    left: 0%;
    right: 0%;
    padding: 0;
  }
}

.Toolbar__Toolbar__2hkfg {
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 0px 2px 10px #ccc;
}

.Toolbar__Toolbar__2hkfg nav {
  height: 100%;
}

.Toolbar__Logo__24rue {
  height: 80%;
}

@media (max-width: 499px) {
  .Toolbar__DesktopOnly__15Sc_ {
    display: none;
  }
}

.Logo__Logo__2A07e {
    background-color: white;
    padding: 0px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 50%;
}

.Logo__Logo__2A07e img {
    height: 100%;
}
.NavigationItem__NavigationItem__3YSjr {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem__NavigationItem__3YSjr a {
    color: #8F5C2C;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem__NavigationItem__3YSjr a:hover,
.NavigationItem__NavigationItem__3YSjr a:active,
.NavigationItem__NavigationItem__3YSjr a.NavigationItem__active__2GQz1{
    color: #40A4C8;
}

@media (min-width: 500px) {
    .NavigationItem__NavigationItem__3YSjr {
        margin: 0;
        display: -webkit-flex;
        display: flex;
        height: 100%;
        width: auto;
        -webkit-align-items: center;
                align-items: center;
    }
    
    .NavigationItem__NavigationItem__3YSjr a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        white-space: nowrap;
    }
    
    .NavigationItem__NavigationItem__3YSjr a:hover,
    .NavigationItem__NavigationItem__3YSjr a:active,
    .NavigationItem__NavigationItem__3YSjr a.NavigationItem__active__2GQz1{
        background-color: #2094A850;
        border-bottom: 4px solid #40A4C8;
        color: white;
    }
}
.NavigationItems__NavigationItems__3HY9i {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
}

@media (min-width: 500px) {
  .NavigationItems__NavigationItems__3HY9i {
    -webkit-flex-flow: row;
            flex-flow: row;
  }
}

.DrawerToggle__DrawerToggle__1eThf {
    width: 40px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle__DrawerToggle__1eThf div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 500px) {
    .DrawerToggle__DrawerToggle__1eThf {
        display: none;
    }
}
.Colors__Red__1MRmH {
  color: #e84118;
}

.Colors__Green__3l_kl {
  color: #2ecc71;
}

.Colors__Yellow__v0WUk {
  color: #f6b93b;
}

.Colors__Purple__2g4Eh {
  color: #8e44ad;
}

.Colors__Blue__38H7G {
  color: #2980b9;
}

.Colors__Gray__MVCLK {
  color: #999;
}

.Colors__LightGray__1UoKD {
  color: #ccc;
}

.Colors__BlueWhiteBackground__P444O {
  background: rgb(173, 173, 173); /* Old browsers */ /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(173, 173, 173, 1) 0%,
    rgba(198, 205, 219, 1) 4%,
    rgba(168, 184, 215, 1) 28%,
    rgba(195, 215, 245, 1) 70%,
    rgba(227, 235, 251, 1) 96%,
    rgba(107, 107, 107, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(173, 173, 173, 1) 0%,
    rgba(198, 205, 219, 1) 4%,
    rgba(168, 184, 215, 1) 28%,
    rgba(195, 215, 245, 1) 70%,
    rgba(227, 235, 251, 1) 96%,
    rgba(107, 107, 107, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#adadad', endColorstr='#6b6b6b',GradientType=0 ); /* IE6-9 */
}

.SideDrawer__SideDrawer__3wvlM {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 500px){
    .SideDrawer__SideDrawer__3wvlM{
        display: none;
    }
}

.SideDrawer__Open__3xsf8 {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.SideDrawer__Close__2S6c7{
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.SideDrawer__Logo__10IHv{
    height: 11%;
    margin-bottom: 32px;
}


.Backdrop__Backdrop__eeZpu {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5)
}
.Footer__Footer__Kzbfj {
  width: 100%;
  margin-top: 20px;
  background-color: #ffffffaa;
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 30;
}

.Footer__Fixed__2oOKm{
  position: fixed;
  bottom: 0%;
}

.Footer__Footer__Kzbfj p {
  font-size: 0.8rem;
  color: #444;
  margin: 10px 0;
  padding: 0;
  color: #444;
}

.Footer__Link__P-w22 {
  color: #0000ffaa;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 500px) {
  .Footer__Footer__Kzbfj {
    margin-top: 0;
    margin-bottom: 10%;
    margin-top: 5%;
  }
}

.BackgroundManager__BackgroundManager__1xCAg {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  z-index: -100;
  -webkit-animation: BackgroundManager__init__3WWX2 5s ease-in-out;
  animation: BackgroundManager__init__3WWX2 5s ease-in-out;
  opacity: 1;
}

@-webkit-keyframes BackgroundManager__init__3WWX2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes BackgroundManager__init__3WWX2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.BackgroundManager__BackgroundManager__1xCAg .BackgroundManager__Left__2iTfQ,
.BackgroundManager__BackgroundManager__1xCAg .BackgroundManager__Right__3vm31 {
  position: absolute;
  background-repeat: no-repeat;
  height: 100%;
  width: 50%;
}

.BackgroundManager__Left__2iTfQ {
  left: 0%;
}

.BackgroundManager__Right__3vm31 {
  right: 0%;
}

.BackgroundManager__Left__2iTfQ img,
.BackgroundManager__Right__3vm31 img {
  position: relative;
  max-height: 100%;
  top: 50%;
  max-width: 100%;
}

.BackgroundManager__Left__2iTfQ img {
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.BackgroundManager__Right__3vm31 img {
  left: 100%;
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
}

@media (max-width: 500px) {
  .BackgroundManager__BackgroundManager__1xCAg .BackgroundManager__Left__2iTfQ {
    width: 100%;
  }
  .BackgroundManager__BackgroundManager__1xCAg .BackgroundManager__Right__3vm31 {
    display: none;
  }
}

.Index__Index__1u_mU {
  padding: 20px 30px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffffdd;
  border: 1px solid #ccc;
  border-top: 0;
  border-radius: 0 0 20px 20px;
}

/* .LeftColumn{
  width: 70%;
  box-sizing: border-box;
  display: inline-block;
}

.RightColumn{
  width: 30%;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: auto;
  height: 100%;
} */

.Index__Index__1u_mU hr {
  border-top: 1px solid #ccc;
  border-right: none;
  border-bottom: none;
  border-left: none;
}

.Index__Index__1u_mU ul {
  list-style: none;
}

.Index__Index__1u_mU ul li::before {
  content: "-";
  color: #2980b9;
  font-weight: 900;
  position: relative;
  left: 2px;
  margin-right: 5px;
  bottom: 1px;
}

.Index__HeadBanner__2e6Vh img {
  display: block;
  background-repeat: no-repeat;
  width: 80%;
  height: auto;
  margin: 0 10%;
}

.Index__Header__3aP_S {
  text-align: center;
  color: #2980b9;
}

.Index__Header__3aP_S span {
  font-size: 0.6em;
  margin-left: 20px;
}

.Index__MiniHeader__2LZUn {
  color: #2980b9;
}

.Index__MiniHeader__2LZUn::before {
  content: "";
  border-left: #2980b9 6px solid;
  position: relative;
  left: 2px;
  bottom: 1px;
  margin-right: 5px;
}

.Index__Paragraph__2Qt12 {
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
  -webkit-align-self: center;
          align-self: center;
  box-sizing: border-box;
}

.Index__Paragraph__2Qt12::before {
  content: "";
  border-left: #2980b9 6px solid;
  position: relative;
  left: 2px;
  margin-right: 5px;
  bottom: 1px;
}

.Index__Announcement__3L3Ak {
  font-weight: 600;
  color: #dc3545;
  font-size: 1.2em;
}

@media (max-width: 1000px) {
  .Index__Index__1u_mU {
    width: 100%;
    margin: 0 auto;
  }
  .Index__Header__3aP_S {
    text-align: center;
    font-size: 1.8rem;
  }
  .Index__Paragraph__2Qt12 {
    width: 90%;
    margin: 10px 5%;
  }
}

.Index__bmc-button__1juuu img {
  width: 27px;
  margin-bottom: 1px;
  box-shadow: none;
  border: none;
  vertical-align: middle;
}
.Index__bmc-button__1juuu {
  line-height: 36px;
  height: 37px;
  text-decoration: none;
  display: inline-block;
  color: #ffffff;
  background-color: #2980b9;
  border-radius: 3px;
  border: 1px solid transparent;
  padding: 0px 9px;
  font-size: 17px;
  letter-spacing: -0.08px;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5);
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
  margin: 10px;
  box-sizing: border-box;
  -webkit-transition: 0.3s all linear;
  transition: 0.3s all linear;
}
.Index__bmc-button__1juuu:hover,
.Index__bmc-button__1juuu:active,
.Index__bmc-button__1juuu:focus {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
  text-decoration: none;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
  opacity: 0.85;
  color: #ffffff;
}

.HeaderBar__HeaderBar__2cMCt {
  border-radius: 10px 10px 0 0;
  padding: 5px 10px;
  color: #ffffffee;
  font-weight: bold;
  text-shadow: 2px 2px 1px #ffffff77;
  box-sizing: border-box;
}

.HeaderBar__Profile__ZJ8zj {
  width: 80%;
  margin: 0 10%;
}

@media (max-width: 1400px) {
  .HeaderBar__Profile__ZJ8zj {
    width: 90%;
    margin: 0 5%;
  }
}

@media (max-width: 1000px) {
  .HeaderBar__Profile__ZJ8zj {
    width: 100%;
    margin: 0;
  }
}

.HeaderBar__FlippedAndCentered__32Z6Y {
  border-radius: 0 0 10px 10px;
  text-align: center;
}

.HeaderBar__MarginTop__2jP3Y {
  margin-top: 20px;
}

.HeaderBar__MarginBottom__20Ltf {
  margin-bottom: 20px;
}

.HeaderBar__HasClicked__39I0U {
  cursor: pointer;
}

.Spinner__Loader__3dgUo,
.Spinner__Loader__3dgUo:before,
.Spinner__Loader__3dgUo:after {
  background: #2980b9;
  -webkit-animation: Spinner__load1__3JQ2Z 1s infinite ease-in-out;
  animation: Spinner__load1__3JQ2Z 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.Spinner__Loader__3dgUo {
  color: #2980b9;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Spinner__Loader__3dgUo:before,
.Spinner__Loader__3dgUo:after {
  position: absolute;
  top: 0;
  content: "";
}
.Spinner__Loader__3dgUo:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Spinner__Loader__3dgUo:after {
  left: 1.5em;
}
@-webkit-keyframes Spinner__load1__3JQ2Z {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes Spinner__load1__3JQ2Z {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.Modal__Modal__cd320 {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 90%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 5%;
  top: 10%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  opacity: 0;
  -webkit-transform: translateY(-100vh);
          transform: translateY(-100vh);
}

.Modal__Modal-Visible__1uaEr {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

@media (min-width: 600px) {
  .Modal__Modal__cd320 {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Modal__Legal__2pGRz {
  left: 5%;
  top: 10%;
  width: 90%;
  max-height: 80%;
  overflow-y: scroll;
}

.Modal__MapModal__1aj2a{
  width: unset;
  left: calc(50% - 142px)
}
