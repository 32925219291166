.BackgroundManager {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  z-index: -100;
  -webkit-animation: init 5s ease-in-out;
  animation: init 5s ease-in-out;
  opacity: 1;
}

@keyframes init {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.BackgroundManager .Left,
.BackgroundManager .Right {
  position: absolute;
  background-repeat: no-repeat;
  height: 100%;
  width: 50%;
}

.Left {
  left: 0%;
}

.Right {
  right: 0%;
}

.Left img,
.Right img {
  position: relative;
  max-height: 100%;
  top: 50%;
  max-width: 100%;
}

.Left img {
  transform: translate(0, -50%);
}

.Right img {
  left: 100%;
  transform: translate(-100%, -50%);
}

@media (max-width: 500px) {
  .BackgroundManager .Left {
    width: 100%;
  }
  .BackgroundManager .Right {
    display: none;
  }
}
